.github-button-link {
  padding-top: 10px;
}

.github-button-link {
  color: white;
}

.github-button-logo {
  width: 25px;
  height: 25px;
  color: white;
  margin-right: 10px;
  margin-bottom: 1px;
}

.join-gitlights-github-img img {
  filter: invert(1);
}

.dark .gitlights-logo-navbar {
  filter: invert(0.75);
}

.gitlights-logo-navbar {
  filter: invert(0.5);
}

.commit-add-del-card .card-body {
  padding: 0 !important;
}

.commit-add-del-card-icon {
  float: left;
  margin: 5px 15px 5px 5px;
}

.widget-item-title-container {
  overflow: hidden;
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
}

.chart-title {
  float: left;
  font-size: 1rem;
  width: auto;
  margin-top: 0.25rem;
}

.advanced-count-up-label {
  font-size: 1rem;
}

.chart-info-icon {
  width: 1rem;
  margin: 0 0 0 1rem;
  cursor: pointer;
  overflow: hidden;
  display: inline;
}

.card-fit-content-height {
  height: fit-content;
}

.multi-stack-histogram-lines-of-code .echarts-for-react {
  margin-top: 2rem;
}

.main-small-widgets-container .card-small-pie-chart {
  padding-bottom: 0px !important;
}

.text-for-bubbles {
  fill: #5e6e82 !important;
}

.dark .text-for-bubbles {
  fill: #fff !important;
}

.kpi-developer-detail-left {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 0rem;
}

.kpi-developer-detail-right {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 0rem;
  margin-right: 1.5rem;
}

.kpi-span-developer-detail {
  padding: 0px !important;
  margin: 0px !important;
  display: block;
}

.compareWithLabelFilter {
  float: left;
  margin: 0.9rem;
}

.avatarTableRow {
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.colWithMin20percent {
  min-width: 22%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.profileDropdownAvatar ul {
  float: right;
}

.my-accounts-save-users {
  float: right;
}

.settings-section label {
  margin: 1rem;
}

.settings-section svg {
  margin-right: 1rem;
}

.navbar .navbar-brand {
  display: inline-flex;
}

.recurringReportContainer h1 {
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.recurringReportContainer h2 {
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navItemMenuLanding {
  margin-right: 1.5rem;
}

.text-error {
  color: red;
}

.goToDashboardButton {
  text-align: center;
}

.goToDashboardButton button {
  margin: 1.2rem;
  cursor: pointer;
}

.title-pricing-card {
  height: 4rem;
}

.subtitle-pricing-card {
  height: 4rem;
}

.pricing-card-selected-plan-badge {
  font-size: 0.9rem !important;
  color: black !important;
}
.date-pciker-quickactions-container{
  width: 80%;
    margin-left: 10%;
}